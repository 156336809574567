import { combineReducers } from "redux";
import containersReducer from "./containers/reducer";
import loginReducer from "./components/login/reducer";
import shippingReducer from "./components/shipping/reducer";
import settingReducer from "./components/settings/reducer";
import sellingReducer from "./components/selling/reducer";
import depositReducer from "./components/deposit/reducer";
import productReducer from "./components/product/reducer";
import showcaseReducer from "./components/showcase/reducer";
import reviewReducer from "./components/review/reducer";
import documentReducer from "./components/document/reducer";
import decorationReducer from "./components/decoration/reducer";
import complaintReducer from "./components/complaint/reducer";
import rfqReducer from "./components/rfq/reducer";
import profileReducer from "./components/profile/reducer";
import landingPageReducer from "./components/landingpage/reducer";

import appsReducer from "./modules/apps/reducer";
import bankReducer from "./modules/bank/reducer";
import chatReducer from "./modules/chat/reducer";
import coinReducer from "./modules/coin/reducer";
import coverageAreaReducer from "./modules/coveragearea/reducer";
import dashboardReducer from "./modules/dashboard/reducer";
import packageReducer from "./modules/package/reducer";
import paymentReducer from "./modules/payment/reducer";
import warehouseReducer from "./modules/warehouse/reducer";
import discussionReducer from "./modules/discussion/reducer";

import landingpages from "./modules/landingpages/reducer";

const rootReducer = combineReducers({
  container: containersReducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  shipping: shippingReducer,
  setting: settingReducer,
  selling: sellingReducer,
  chat: chatReducer,
  deposit: depositReducer,
  product: productReducer,
  showcase: showcaseReducer,
  review: reviewReducer,
  document: documentReducer,
  discussion: discussionReducer,
  decoration: decorationReducer,
  complaint: complaintReducer,
  rfq: rfqReducer,
  coin: coinReducer,
  bank: bankReducer,
  profile: profileReducer,
  apps: appsReducer,
  landingpage: landingPageReducer,
  warehouse: warehouseReducer,
  package: packageReducer,
  coveragearea: coverageAreaReducer,
  payment: paymentReducer,
  landingpages: landingpages,
});

export default rootReducer;
