export default {
  SET_SENDER: "set-sender-package",
  SET_RECEIVER: "set-receiver-package",
  SET_WAREHOUSE_ID: "set-warehouse-id-package",
  SET_ITEMS: "set-items-package",
  SET_ADD_ITEM: "set-add-item-package",
  SET_REMOVE_ITEM: "set-remove-item-package",
  SET_QTY_ITEM: "set-qty-item-package",
  SET_DETAIL: "set-detail-package",
  SET_SHIPPING: "set-shipping-package",
  RESET_SHIPPING: "reset-shipping-package",
  RESET_SHIPPING_SERVICE: "reset-shipping-service-package",
  SET_SHIPPING_INSURANCE: "set-shipping-insurance-package",
  RESET_SHIPPING_INSURANCE: "reset-shipping-insurance-package",
  SET_SHIPPING_COD: "set-shipping-cod-package",
  SET_ITEM_VALUE: "set-item-value-package",
  SET_ERROR: "set-error-package",
  RESET_ERROR: "reset-error-package",

  SET_CUSTOM_COD: "set-custom-cod-package",
  SET_CUSTOM: "set-custom-package",
  SET_CUSTOM_SUCCESS: "set-custom-package-success",

  SET_ADDED: "set-added-package",
  CREATE_PACKAGE: "create-package",
  CREATE_PACKAGE_SUCCESS: "create-package-success",
  IS_LOADING_CREATE_PACKAGE: "is-loading-create-package",
  RESET_CREATE_PACKAGE: "reset-create-package",

  GET_NOTIFICATION: "get-notification-package",
  GET_NOTIFICATION_SUCCESS: "get-notification-package-success",
  IS_LOADING_GET_NOTIFICATION: "is-loading-get-notification-package",
  RESET_GET_NOTIFICATION: "reset-get-notification-package",

  SET_MULTIPLE_IDS: "set-multiple-ids-package",
  GET_MULTIPLE_IDS: "get-multiple-package",
  GET_MULTIPLE_IDS_SUCCESS: "get-multiple-package-success",
  IS_LOADING_GET_MULTIPLE_IDS: "is-loading-get-multiple-package",
  RESET_GET_MULTIPLE_IDS: "reset-get-multiple-package",

  SET_IDS_PROCESS: "set-ids-process-package",
  GET_PROCESS_NEW: "get-process-new-package",
  GET_PROCESS_PAYMENT: "get-process-payment-package",
  GET_PROCESS_RECEIPT: "get-process-receipt-package",
  GET_PROCESS_SUCCESS: "get-process-package-success",
  IS_LOADING_GET_PROCESS: "is-loading-get-process-package",
  RESET_GET_PROCESS: "reset-get-process-package",

  GET_HISTORY_WAITING_PICKUP: "get-history-waiting-pickup",
  GET_HISTORY_SHIPPED: "get-history-shipped",
  GET_HISTORY_ARRIVED: "get-history-arrived",
  GET_HISTORY_DONE: "get-history-done",
  GET_HISTORY_RETURN: "get-history-return",
  GET_HISTORY_CANCELED: "get-history-canceled",
  GET_HISTORY_SUCCESS: "get-history-success",
  IS_LOADING_GET_HISTORY_SUCCESS: "is-loading-get-history",
  RESET_HISTORY: "reset-history",

  CREATE_REQUEST_PICKUP_COD: "create-request-pickup-cod-package",
  CREATE_REQUEST_PICKUP_NONCOD: "create-request-pickup-noncod-package",
  CREATE_REQUEST_PICKUP_SUCCESS: "create-request-pickup-package-success",
  IS_LOADING_CREATE_REQUEST_PICKUP_SUCCESS:
    "is-loading-create-request-pickup-package-success",
  RESET_CREATE_REQUEST_PICKUP_SUCCESS: "reset-create-request-pickup-package",

  REMOVE_SINGLE_PACKAGE: "remove-single-package",
  REMOVE_MULTIPLE_PACKAGE: "remove-multiple-package",
  REMOVE_PACKAGE_SUCCESS: "remove-package-success",
  IS_LOADING_REMOVE_PACKAGE: "is-loading-remove-package",
  RESET_REMOVE_PACKAGE: "reset-remove-package",

  UPDATE_UPLOADED_PROOF_PAYMENT_PACKAGE:
    "update-uploaded-proof-payment-package",
  UPDATE_RECEIPT_PRINTED_MULTIPLE_PACKAGE:
    "updated-receipt-printed-multiple-package",
  UPDATE_PACKAGE_SUCCESS: "updated-package-success",
  IS_LOADING_UPDATE_PACKAGE: "is-loading-updated-package",
  RESET_UPDATE_PACKAGE: "reset-updated-package",

  SET_UPDATE_AWB_PROCESS_PACKAGE: "set-update-awb-process-package",

  VOID_PACKAGE: "void-package",
  VOID_PACKAGE_SUCCESS: "void-package-success",
  IS_LOADING_VOID_PACKAGE: "is-loading-void-package",
  RESET_VOID_PACKAGE: "reset-void-package",

  GET_RECEIVERS_PACKAGE: "get-receivers-package",
  GET_RECEIVERS_PACKAGE_SUCCESS: "get-receivers-package-success",
  IS_LOADING_GET_RECEIVERS_PACKAGE: "is-loading-get-receivers-package",
  RESET_GET_RECEIVERS_PACKAGE: "reset-get-receivers-package",

  RESET_PACKAGE: "reset-package",
};
