import types from "./constant";

const initialState = {
  count: 0,
  data: [],
  detail: {},
  create: {},
  update: {},
  gallery: {
    count: 0,
    photos: [],
    l_get: false,
    l_upload: false,
    l_delete: false,
  },
  isLoadingGet: false,
  isLoadingAdd: false,
  isLoadingUpdate: false,
  isLoadingDelete: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANDING_PAGE_PRODUCTS_SUCCESS:
      return { ...state, data: action.payload };
    case types.SET_COUNT_GET_LANDING_PAGE_PRODUCTS:
      return { ...state, count: action.payload };
    case types.IS_LOADING_GET_LANDING_PAGE_PRODUCTS:
      return { ...state, isLoadingGet: action.payload };
    case types.GET_LANDING_PAGE_PRODUCT_SUCCESS:
      return { ...state, detail: action.payload };
    case types.IS_LOADING_GET_LANDING_PAGE_PRODUCT:
      return { ...state, isLoadingGet: action.payload };
    case types.RESET_LANDING_PAGE_PRODUCT:
      return { ...state, detail: {} };
    case types.ADD_LANDING_PAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        create: action.payload,
        data: [action.payload, ...state.data],
      };
    case types.IS_LOADING_ADD_LANDING_PAGE_PRODUCT:
      return { ...state, isLoadingAdd: action.payload };
    case types.RESET_ADD_LANDING_PAGE_PRODUCT:
      return { ...state, create: {} };
    case types.DELETE_LANDING_PAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: state.data.filter((item) => item._id !== action.payload),
      };
    case types.IS_LOADING_DELETE_LANDING_PAGE_PRODUCT:
      return { ...state, isLoadingDelete: action.payload };
    case types.UPDATE_LANDING_PAGE_PRODUCT_SUCCESS:
      return { ...state, update: action.payload };
    case types.IS_LOADING_UPDATE_LANDING_PAGE_PRODUCT:
      return { ...state, isLoadingUpdate: action.payload };
    case types.RESET_UPDATE_LANDING_PAGE_PRODUCT:
      return { ...state, update: {} };

    case types.GET_PHOTO_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          photos: [
            ...new Map(
              [...state.gallery.photos, ...action.payload].map((item) => [
                item["_id"],
                item,
              ])
            ).values(),
          ],
        },
      };
    case types.IS_LOADING_GET_PHOTO_GALLERY:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          l_get: action.payload,
        },
      };
    case types.SET_COUNT_PHOTO_GALLERY:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          count: action.payload,
        },
      };
    case types.UPLOAD_PHOTO_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          photos: [...action.payload, ...state.gallery.photos],
        },
      };
    case types.IS_LOADING_UPLOAD_PHOTO_GALLERY:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          l_upload: action.payload,
        },
      };
    case types.DELETE_PHOTO_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          photos: state.gallery.photos.filter(
            (item) => item._id !== action.payload
          ),
        },
      };
    case types.IS_LOADING_DELETE_PHOTO_GALLERY:
      return {
        ...state,
        gallery: {
          ...state.gallery,
          l_delete: action.payload,
        },
      };

    default:
      return state;
  }
};

export const getLandingPageProducts = (payload) => ({
  type: types.GET_LANDING_PAGE_PRODUCTS,
  payload,
});
export const getLandingPageProductsSuccess = (payload) => ({
  type: types.GET_LANDING_PAGE_PRODUCTS_SUCCESS,
  payload,
});
export const setCountGetLandingPageProducts = (payload) => ({
  type: types.SET_COUNT_GET_LANDING_PAGE_PRODUCTS,
  payload,
});
export const setIsLoadingGetLandingPageProducts = (payload) => ({
  type: types.IS_LOADING_GET_LANDING_PAGE_PRODUCTS,
  payload,
});

export const getDetailLandingPageProduct = (payload) => ({
  type: types.GET_LANDING_PAGE_PRODUCT,
  payload,
});
export const getDetailLandingPageProductSuccess = (payload) => ({
  type: types.GET_LANDING_PAGE_PRODUCT_SUCCESS,
  payload,
});
export const setIsLoadingLandingPageProduct = (payload) => ({
  type: types.IS_LOADING_GET_LANDING_PAGE_PRODUCT,
  payload,
});
export const resetDetailLandingPageProduct = (payload) => ({
  type: types.RESET_LANDING_PAGE_PRODUCT,
});
export const addLandingPageProduct = (payload) => ({
  type: types.ADD_LANDING_PAGE_PRODUCT,
  payload,
});
export const addLandingPageProductSuccess = (payload) => ({
  type: types.ADD_LANDING_PAGE_PRODUCT_SUCCESS,
  payload,
});
export const setIsLoadingAddLandingPageProduct = (payload) => ({
  type: types.IS_LOADING_ADD_LANDING_PAGE_PRODUCT,
  payload,
});
export const resetAddLandingPageProduct = (payload) => ({
  type: types.RESET_ADD_LANDING_PAGE_PRODUCT,
  payload,
});

export const updateLandingPageProduct = (payload) => ({
  type: types.UPDATE_LANDING_PAGE_PRODUCT,
  payload,
});
export const updateLandingPageProductSuccess = (payload) => ({
  type: types.UPDATE_LANDING_PAGE_PRODUCT_SUCCESS,
  payload,
});
export const setIsLoadingUpdateLandingPageProduct = (payload) => ({
  type: types.IS_LOADING_UPDATE_LANDING_PAGE_PRODUCT,
  payload,
});
export const resetUpdateLandingPageProduct = (payload) => ({
  type: types.RESET_UPDATE_LANDING_PAGE_PRODUCT,
  payload,
});

export const deleteLandingPageProduct = (payload) => ({
  type: types.DELETE_LANDING_PAGE_PRODUCT,
  payload,
});
export const deleteLandingPageProductSuccess = (payload) => ({
  type: types.DELETE_LANDING_PAGE_PRODUCT_SUCCESS,
  payload,
});
export const setIsLoadingDeleteLandingPageProduct = (payload) => ({
  type: types.IS_LOADING_DELETE_LANDING_PAGE_PRODUCT,
  payload,
});

export const getPhotoGallery = (payload) => ({
  type: types.GET_PHOTO_GALLERY,
  payload,
});
export const getPhotoGallerySuccess = (payload) => ({
  type: types.GET_PHOTO_GALLERY_SUCCESS,
  payload,
});
export const setIsLoadingGetPhotoGallery = (payload) => ({
  type: types.IS_LOADING_GET_PHOTO_GALLERY,
  payload,
});
export const setCountPhotoGallery = (payload) => ({
  type: types.SET_COUNT_PHOTO_GALLERY,
  payload,
});

export const uploadPhotoGallery = (payload) => ({
  type: types.UPLOAD_PHOTO_GALLERY,
  payload,
});
export const uploadPhotoGallerySuccess = (payload) => ({
  type: types.UPLOAD_PHOTO_GALLERY_SUCCESS,
  payload,
});
export const setIsLoadingUploadPhotoGallery = (payload) => ({
  type: types.IS_LOADING_UPLOAD_PHOTO_GALLERY,
  payload,
});

export const deletePhotoGallery = (payload) => ({
  type: types.DELETE_PHOTO_GALLERY,
  payload,
});
export const deletePhotoGallerySuccess = (payload) => ({
  type: types.DELETE_PHOTO_GALLERY_SUCCESS,
  payload,
});
export const setIsLoadingDeletePhotoGallery = (payload) => ({
  type: types.IS_LOADING_DELETE_PHOTO_GALLERY,
  payload,
});
