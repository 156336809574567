import { put, call, takeLatest, delay } from "redux-saga/effects";
import Api from "../../services";
import types from "./constant";
import * as actions from "./reducer";
import * as actionsPackage from "../../modules/package/reducer";

function* watchGetShipping(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingShipping(true));
  try {
    const response = yield call(Api.shipping.get, payload);
    const { data } = response;
    if (data.success) {
      yield put(
        actions.getShippingSuccess(
          data.data.length > 0
            ? data.data.map((item) => item.service_type_id)
            : []
        )
      );
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingShipping(false));
  } finally {
    yield put(actions.setIsLoadingShipping(false));
  }
}
function* watchUpdateShipping(value) {
  const { payload } = value;
  yield put(actions.setIsLoadingUpdateShipping(true));
  try {
    const response = yield call(Api.shipping.update, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.updateShippingSuccess(payload.service_type_ids));
      yield put(actions.updatedShipping(true));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingUpdateShipping(false));
  } finally {
    yield put(actions.setIsLoadingUpdateShipping(false));
    yield delay(5000);
    yield put(actions.updatedShipping(false));
  }
}
function* watchGetCities() {
  yield put(actions.setIsLoadingCities(true));
  try {
    const response = yield call(Api.shipping.cities);
    const { data } = response;
    if (data.success) {
      yield put(actions.getCitiesSuccess(data.data));
    } else {
      throw Error;
    }
  } catch (e) {
    yield put(actions.setIsLoadingCities(false));
  } finally {
    yield put(actions.setIsLoadingCities(false));
  }
}
function* watchGetSchedules(value) {
  yield put(actions.setIsLoadingGetSchedulesPickup(true));
  const { payload } = value;
  try {
    const response = yield call(Api.shipping.schedules, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.getSchedulesPickupSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingGetSchedulesPickup(false));
  } finally {
    yield put(actions.setIsLoadingGetSchedulesPickup(false));
  }
}
function* watchCheckTracking(value) {
  yield put(actions.setIsLoadingCheckTracking(true));
  const { payload } = value;
  try {
    const checkNumberPackage = (order_id) => order_id.includes("PLG-TLPID");
    const response = yield call(Api.shipping.tracking, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkTrackingSuccess(data.data));
      //tambahkan actions update to process package / order to checking tracking
      if (checkNumberPackage(payload.order_id)) {
        yield put(actionsPackage.setUpdateAWBProcessPackage(data.data.details));
      }
    }
  } catch (e) {
    yield put(actions.setIsLoadingCheckTracking(false));
  } finally {
    yield put(actions.setIsLoadingCheckTracking(false));
  }
}
function* watchCheckCosts(value) {
  yield put(actions.setIsLoadingCheckCosts(true));
  const { payload } = value;
  try {
    const response = yield call(Api.checkcost.multiple, payload);
    const { data } = response;
    if (data.success) {
      yield put(actions.checkCostsSuccess(data.data));
    }
  } catch (e) {
    yield put(actions.setIsLoadingCheckCosts(false));
  } finally {
    yield put(actions.setIsLoadingCheckCosts(false));
  }
}
const sagas = [
  takeLatest(types.GET_SHIPPING, watchGetShipping),
  takeLatest(types.UPDATE_SHIPPING, watchUpdateShipping),
  takeLatest(types.GET_CITIES, watchGetCities),
  takeLatest(types.GET_SCHEDULES_PICKUP, watchGetSchedules),
  takeLatest(types.CHECK_TRACKING, watchCheckTracking),
  takeLatest(types.CHECK_COSTS, watchCheckCosts),
];

export default sagas;
